import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.scoreItems.length > 0)?_c('div',{class:_vm.VRMode ? 'px-1' : 'px-4'},[(!_vm.VRMode)?_c('div',{staticClass:"d-flex overflow-x-auto overflow-y-hidden",staticStyle:{"font-size":"30px","font-weight":"900"}},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.sessionsListSection.scoresCard.cardTitle"))+" "),_c(VSpacer)],1):_vm._e(),_c(_vm.VRMode ? 'v-card' : 'div',{tag:"component",class:("d-flex mt-4 pb-4" + (_vm.VRMode ? ' flex-wrap' : ' overflow-x-auto overflow-y-hidden')),attrs:{"outlined":_vm.VRMode}},_vm._l((_vm.scoreItems),function(s,i){return _c('div',{key:i,class:("score-container" + (_vm.VRMode ? ' score-container-vr' : ''))},[_c(VExpansionPanels,{attrs:{"readonly":true,"disabled":true,"flat":""},model:{value:(_vm.expandedPanel),callback:function ($$v) {_vm.expandedPanel=$$v},expression:"expandedPanel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"d-flex flex-column justify-start",attrs:{"hide-actions":""}},[_c('div',{class:("score-icon" + (_vm.VRMode ? ' score-icon-vr' : ''))},[_c(VIcon,{attrs:{"color":"main","size":"24px"}},[_vm._v(" "+_vm._s(s.icon)+" ")])],1),_c('div',{staticClass:"score"},[_c('div',[_c(VProgressCircular,{attrs:{"value":_vm.scoreFactor(s.value) * 100,"rotate":"-90","size":"100","width":"4","color":_vm.scoreColor(s.value)}},[_c('div',{staticClass:"score-value"},[_vm._v(_vm._s(s.value))])])],1)]),_c('div',{staticClass:"score-title text-truncate"},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(s.title))])])]),_c(VExpansionPanelContent,[_c('div',[_vm._v(" "+_vm._s(s.tip)+" ")]),(s.icon === 'mdi-delete')?_c('div',[_c('span',{staticClass:"filler-tip"},[_c('br'),_vm._v("Manche Füllwörter wie äh und ähm werden zur Zeit nicht korrekt erfasst. Wir arbeiten an einem Update!")])]):_vm._e()])],1)],1)],1)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }