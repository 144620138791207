
















































































































































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import { FileObject, LookupWord, SessionScores } from "@/core/models";
import api from "@/core/utils/api";
import {
  SessionVideoPlayer,
  VideoRecorderWithBottomToolbar,
} from "@/components/common";
import LocalStorage from "@/core/utils/LocalStorage";
import { AiFeedback, UserClaims } from "@/core/models";
import { isDev, isLocal } from "@/settings";

@Component({
  components: { VideoRecorderWithBottomToolbar, SessionVideoPlayer },
})
export default class SessionControlCard extends Vue {
  @Prop({ default: () => false }) enableFeedback!: boolean;
  @Getter("currentSession/id") id!: number;
  @Getter("currentSession/scores") scores?: SessionScores;
  @Getter("currentSession/wpm") wpm!: number;
  @Getter("currentSession/pausesPerMinute") pausesPerMinute!: number;
  @Getter("currentSession/lookupWords") lookupWords!: LookupWord[];
  @Getter("currentSession/length") length!: number;
  @Getter("currentSession/feedback") feedback!: FileObject | null;
  @Getter("currentSession/aiFeedback") aiFeedback!: AiFeedback;
  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("sessions/aiFeedbackMessage") aiFeedbackMessage!: string;
  @Getter("sessions/aiFeedbackLoading") aiFeedbackLoading!: boolean;
  @Getter("profile/userClaims") userClaims!: UserClaims[];
  @Action("sessions/updateShared") updateSharedSession!: (data: any) => void;
  @Action("currentSession/updateFeedback") updateFeedback!: (data: any) => void;
  @Action("sessions/getAIFeedbackForSingleSession")
  getAIFeedbackForSingleSession!: (i: number) => Promise<void>;
  @Action("sessions/getSingleSession")
  getSingleSession!: (i: number) => Promise<void>;
  @Action("profile/fetchPermissions") fetchPermissions!: () => Promise<void>;

  existingAIFeedback: AiFeedback | undefined;

  deleting = false;
  openRecordFeedbackDialog = false;
  openViewFeedbackDialog = false;
  openDeleteFeedbackDialog = false;
  playFeedback = true;
  stopFeedback = false;
  feedbackUrl: string | undefined = undefined;
  loading = false;

  VRMode = LocalStorage.getVRMode();

  @Emit("toggle-expand-scores")
  onButtonClick() {
    //return true;
  }

  get aiFeedbackFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "ai_feedback:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get aiFeedbackRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "ai_feedback:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  isAIFeedbackOpen = false;

  getAIFeedback = (id: number) => {
    this.getAIFeedbackForSingleSession(id).then(async () => {
      await this.getSingleSession(id);
      await this.fetchPermissions();
    });
  };

  async recalculateScores() {
    try {
      const sessionId = this.id ? this.id.toString() : null;
      if (!sessionId) throw new Error("Session ID fehlt oder ist ungültig.");

      this.loading = true;

      const response = await api.get(
        `/api/Sessions/RecalculateScores?sessionId=${sessionId}`,
      );

      if (response && response.data) this.$emit("scoresUpdated", response.data);
      else throw new Error("Ungültige Antwort vom Server.");
    } catch (error) {
      console.error("Fehler beim Neuberechnen der Scores:", error);
    } finally {
      this.loading = false;
    }
  }

  addTestEmojis = (text: string) => {
    if (typeof text !== "string") return text; // Return original value if is not a string

    return isLocal || isDev
      ? text
          .replace("Stärken", "Stärken :muscle:")
          .replace("Schwächen", "Schwächen :sweat:")
          .replace("Bewertung", ":chart_with_upwards_trend: Bewertung")
          .replace("Plausibilität", ":dart: Plausibilität")
      : text;
  };

  toggleRecordFeedbackDialog() {
    this.openRecordFeedbackDialog = true;
  }

  toggleViewFeedbackDialog() {
    this.feedbackUrl = this.feedback?.URI;
    this.openViewFeedbackDialog = true;
  }

  toggleDeleteFeedbackDialog() {
    this.openDeleteFeedbackDialog = true;
  }

  get ititle() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.infoTitle");
  }
  get itext() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.infoText");
  }
  get headline() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.cardTitle");
  }

  @Watch("aiFeedback", { immediate: true })
  onAiFeedbackUpdate() {
    console.log("aiFeedback updated", this.aiFeedback);
    this.existingAIFeedback = this.aiFeedback;
  }

  @Watch("id", { immediate: true })
  onSessionChange() {
    this.existingAIFeedback = this.aiFeedback;
  }

  uploading = false;

  async deleteFeedback() {
    this.deleting = true;
    try {
      await api
        .delete("/api/sessions/feedback/" + this.id.toString())
        .then(session => {
          console.log("deleted", session);
          this.updateFeedback(session);
          this.updateSharedSession({ sessionId: this.id, hasFeedback: false });
        });
    } catch (error) {
      console.log(error);
    }
    this.deleting = false;
    this.openDeleteFeedbackDialog = false;
  }

  async uploadFeedback({ blob }: { blob: Blob }) {
    this.uploading = true;
    try {
      const data = new FormData();
      data.append("sessionId", this.id.toString());
      data.append("feedback", blob);
      await api
        .post("/api/sessions/feedback", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(session => {
          console.log("uploaded", session);
          this.updateFeedback(session);
          this.updateSharedSession({ sessionId: this.id, hasFeedback: true });
        });
    } catch (error) {
      console.log(error);
    }
    this.uploading = false;
    this.openRecordFeedbackDialog = false;
  }
}
