



































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import SessionControls from "./details/SessionControls.vue";
import PresentationAnalysisDetails from "@/components/sessions/details/PresentationAnalysisDetails.vue";
import TextAnalysisDetails from "@/components/sessions/details/TextAnalysisDetails.vue";
import AudioAnalysisDetails from "@/components/sessions/details/AudioAnalysisDetails.vue";
import SessionScoresCard from "@/components/sessions/cards/SessionScoresCard.vue";
import SessionControlCard from "@/components/sessions/cards/SessionControlCard.vue";
import LocalStorage from "@/core/utils/LocalStorage";
import { AiFeedback, UserClaims } from "@/core/models";
import { isDev, isLocal } from "@/settings";

@Component({
  computed: {
    isDev() {
      return isDev;
    },
    isLocal() {
      return isLocal;
    },
  },
  components: {
    PresentationAnalysisDetails,
    AudioAnalysisDetails,
    TextAnalysisDetails,
    SessionControls,
    SessionScoresCard,
    SessionControlCard,
  },
})
export default class SessionDetails extends Vue {
  @Prop({ default: () => false }) enableFeedback!: boolean;
  //@Prop({ default: () => false }) expandScores!: boolean;
  @Getter("currentSession/id") id!: number;
  @Getter("currentSession/has") has!: boolean;
  @Getter("currentSession/loading") loading!: boolean;
  @Getter("currentSession/length") sessionLength!: number;
  @Getter("currentSession/aiFeedback") aiFeedback!: AiFeedback;
  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("sessions/gettingSingleSession") singleLoading!: boolean;
  @Getter("sessions/retrievedSessionId") retrievedSessionId!: number;
  @Getter("sessions/aiFeedbackMessage") aiFeedbackMessage!: string;
  @Getter("sessions/aiFeedbackLoading") aiFeedbackLoading!: boolean;
  @Getter("profile/userClaims") userClaims!: UserClaims[];
  @Action("audio/setTotalTime") setTotalTime!: Function;
  @Action("sessions/getAIFeedbackForSingleSession")
  getAIFeedbackForSingleSession!: (i: number) => Promise<void>;
  @Action("sessions/getSingleSession")
  getSingleSession!: (i: number) => Promise<void>;
  @Action("profile/fetchPermissions") fetchPermissions!: () => Promise<void>;
  expandedPanels: number[] = [];

  existingAIFeedback: AiFeedback | undefined;

  expandScores: boolean = false;

  handleExpandScores() {
    console.log("DEBUG EXPAND SCORES!");
    this.expandScores = !this.expandScores;
  }

  get aiFeedbackFastQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "ai_feedback:fast",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  get aiFeedbackRelaxedQuota() {
    if (this.userClaims) {
      const quotaClaim = this.userClaims.filter(
        x => x.type === "ai_feedback:relaxed",
      );
      if (quotaClaim[0]) {
        if (parseInt(quotaClaim[0].value as string, 10) === -1)
          return "unlimited";

        return parseInt(quotaClaim[0].value as string, 10);
      }
      return 0;
    }
    return 0;
  }

  isAIFeedbackOpen = false;

  VRMode = LocalStorage.getVRMode();

  getAIFeedback = (id: number) => {
    this.getAIFeedbackForSingleSession(id).then(async () => {
      await this.getSingleSession(id);
      await this.fetchPermissions();
    });
  };

  addTestEmojis = (text: string) => {
    if (typeof text !== "string") return text; // Return original value if is not a string

    return isLocal || isDev
      ? text
          .replace("Stärken", "Stärken :muscle:")
          .replace("Schwächen", "Schwächen :sweat:")
          .replace("Bewertung", ":chart_with_upwards_trend: Bewertung")
          .replace("Plausibilität", ":dart: Plausibilität")
      : text;
  };

  get isLoading() {
    return (
      this.loading ||
      (this.singleLoading && this.retrievedSessionId === this.id)
    );
  }

  @Watch("sessionLength", { immediate: true })
  onLengthUpdate() {
    if (this.sessionLength !== 0) this.setTotalTime(this.sessionLength);
  }

  @Watch("aiFeedback", { immediate: true })
  onAiFeedbackUpdate() {
    console.log("aiFeedback updated", this.aiFeedback);
    this.existingAIFeedback = this.aiFeedback;
  }

  @Watch("id", { immediate: true })
  onSessionChange() {
    this.existingAIFeedback = this.aiFeedback;
  }

  mounted() {
    this.fetchPermissions();
  }
}
