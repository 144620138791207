





















import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { TranscriptionSlides } from "@/components/common";
import LocalStorage from "@/core/utils/LocalStorage";
import { TranscriptionSlide } from "@/core/models";

@Component({
  components: {
    TranscriptionSlides,
  },
})
export default class PresentationAnalysisDetails extends Vue {
  @Getter("currentSession/videoUrl") videoUrl!: string;
  @Getter("currentSession/currentSlide") currentSlide!: TranscriptionSlide;

  VRMode = LocalStorage.getVRMode();
}
