import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-1"},[(!_vm.isLoading && _vm.has)?_c('div',{staticClass:"d-flex flex-column session-details"},[_c('div',{style:(!_vm.VRMode
          ? 'flex: 1; overflow: auto; padding: 20px'
          : 'flex: 1; overflow: auto; padding: 8px 20px 20px 20px')},[_c('SessionControlCard',{attrs:{"enable-feedback":_vm.enableFeedback},on:{"toggle-expand-scores":_vm.handleExpandScores}}),_c('SessionScoresCard',{attrs:{"expand-scores":_vm.expandScores}}),_c('PresentationAnalysisDetails'),(_vm.isLocal || _vm.isDev)?_c(VExpansionPanels,{staticClass:"highlight-dev",attrs:{"multiple":""},model:{value:(_vm.expandedPanels),callback:function ($$v) {_vm.expandedPanels=$$v},expression:"expandedPanels"}},[_c('div',{staticClass:"dev-label"},[_vm._v("DEV")]),_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"custom-header"},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.sessionsListSection.audioAnalHdg"))+" ")]),_c(VExpansionPanelContent,{attrs:{"color":"#eceff2"}},[_c('AudioAnalysisDetails')],1)],1),(!_vm.VRMode)?_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"custom-header"},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.sessionsListSection.textAnalHdg"))+" ")]),_c(VExpansionPanelContent,{attrs:{"color":"#eceff2"}},[_c('TextAnalysisDetails')],1)],1):_vm._e()],1):_vm._e()],1),_c('SessionControls')],1):_vm._e(),(_vm.isLoading)?_c('div',[_c(VOverlay,[_c('div',{staticClass:"d-flex flex-column align-center"},[_c(VProgressCircular,{attrs:{"size":"64","indeterminate":""}}),_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.yourSessionsSection.fetching"))+" ")])],1)])],1):_vm._e(),(!_vm.has)?_c('div',{staticClass:"d-flex flex-1"},[_c('div',{staticClass:"d-flex flex-1 justify-center align-center text-h6 pa-4"},[_vm._v(" "+_vm._s(_vm.$t("sess.noneselected"))+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }