








































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { AnalysisStatus, SharedSession } from "@/core/models";
import { formatFromSeconds } from "@/core/utils/formatTime";
import { Getter } from "vuex-class";

@Component
export default class SubmittedSessionItem extends Vue {
  @Prop({ default: () => ({} as SharedSession) }) session!: SharedSession;
  @Getter("currentSession/id") currentSessionId!: number;

  completed: boolean = false;

  get length() {
    const timespan = this.session.duration.split(":");
    let total = 0;
    total += Number(timespan[0]) * 3600;
    total += Number(timespan[1]) * 60;
    total += Number(timespan[2]);
    return formatFromSeconds(total);
  }

  get progress() {
    const status = this.session.sessionStatus;
    if (status === AnalysisStatus.AnalyzingAudio) return 7;
    else if (status === AnalysisStatus.AudioDone) return 15;
    else if (status === AnalysisStatus.AnalyzingText) return 33;
    else if (status === AnalysisStatus.TextDone) return 75;
    else if (status === AnalysisStatus.Succeeded) return 100;
    else return 0;
  }

  get selected() {
    return this.currentSessionId === this.session.sessionId;
  }
}
